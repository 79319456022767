import React from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
import Button from "../../components/button"
import { ProjectCard } from "../../components/card"
import "./index.scss"

const Index = ({ data }) => {
  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 3000,
    autoplay: true
  }

  const banners = data.allHomeBanners.edges[0].node.banners

  const renderBanner = () => (
    <div>
      <Slider {...slickSettings}>
        {banners.map(banner => (
            <div
              className="homeScreen-banner"
              key={banner.url}
            >
              <img
                className="homeScreen-bannerImage"
                src={banner.url}
                alt=""
              />
            </div>
          )
        )}
      </Slider>
    </div>
  )

  const renderProjectLogos = () => (
    <div className="homeScreen-projectLogos u-paddingVertical24 u-paddingHorizontal16 u-md-paddingHorizontal0">
      {
        data.allProject.edges.slice(2).map(project => (
          <div
            className="homeScreen-projectLogo"
            key={project.node.name}
          >
            <img
              src={project.node.logo}
              alt={project.node.name}
            />
          </div>
        ))
      }
    </div>
  )

  const renderProjects = () => (
    <div
      className="homeScreen-projects u-paddingVertical64 u-paddingHorizontal16 u-md-paddingHorizontal0 u-textAlignCenter">
      <h2 className="u-margin0 u-heading2">Discover Our Projects</h2>
      <div className="homeScreen-projectsFeed u-marginTop64">
        {
          data.allProject.edges.map(project => {
            if (project.node.shouldAppearHome) {
              return (
                <ProjectCard
                  key={project.node.id}
                  className="homeProjectCard-item"
                  project={project.node}
                />
              )
            }

            return null
          })
        }
      </div>
      <Link to="/projects">
        <Button className="u-marginTop64">
          <p className="u-margin0">Discover Our Projects</p>
        </Button>
      </Link>
    </div>
  )

  const renderAboutUs = () => (
    <div
      className="homeScreen-aboutUs u-paddingVertical64 u-paddingHorizontal16 u-md-paddingHorizontal0 u-textAlignCenter"
    >
      <h2 className="u-margin0 u-heading2">About Us</h2>
      <div className="homeScreen-aboutUsCopyWrapper">
        <p className="homeScreen-aboutUsCopy u-margin0 u-marginTop40 u-body1">
          Sentra Property Development berdiri tahun 2010
          dengan visi menciptakan hunian TOD yang terhubung
          dengan kota Jakarta dan sekitarnya, dengan harga
          terjangkau, cicilan ringan, dan kluster terintegrasi
        </p>
      </div>
    </div>
  )

  return (
    <div className="homeScreen">
      {renderBanner()}
      {renderProjectLogos()}
      {renderProjects()}
      {renderAboutUs()}
    </div>
  )
}

export default Index
