import React from "react"
import { Link } from "gatsby"
import cls from "classnames"
import Button from "../../button"
import "./index.scss"

const ProjectCard = ({ project, className }) => {
  const ratio = project.cover.height / project.cover.width * 100

  return (
    <div className={cls("projectCard", className)}>
      <div
        className="projectCard-imageWrapper"
        style={{ paddingTop: `${ratio}%` }}
      >
        <img
          className="projectCard-image"
          src={project.cover.url}
          alt={project?.name ?? ""}
        />
      </div>
      <div className="projectCard-info">
        <h2 className="u-heading2 u-margin0 u-marginTop40">{project?.name ?? ""}</h2>
        <Link to={`/projects/${project.id}`}>
          <Button className="u-marginTop24">
            <p className="u-margin0">Discover</p>
          </Button>
        </Link>
      </div>
    </div>
  )
}


export default ProjectCard
