import React from "react"
import { Link } from "gatsby"
import cls from "classnames"
import "./index.scss"

const NewsCard = ({ news, className }) => (
  <div className={cls("newsCard", className)}>
    <Link to={`/news/${news.slug}`}>
      <div className="newsCard-imageContainer">
        <div className="newsCard-imageWrapper">
          <img
            className="newsCard-image"
            src={news.cover}
            alt={news?.title ?? ""}
          />
        </div>
      </div>
      <div className="newsCard-infoWrapper">
        <h2 className="u-margin0 u-heading2">{news.title}</h2>
        <p className="newsCard-date u-body2">{news.date}</p>
        <p className="u-margin0 u-marginTop24 u-body1">{news.excerpt}</p>
      </div>
    </Link>
  </div>
)

export default NewsCard

