import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Index from "../screens/index"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <Index
        data={data}
      />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomeProjects {
    allHomeBanners {
      edges {
        node {
          banners {
            url
            width
            height
          }
        }
      }
    }
    allProject(sort: {order: DESC, fields: name}) {
      edges {
        node {
          id
          name
          excerpt
          logo
          cover {
            url
            width
            height
          }
          shouldAppearHome
        }
      }
    }
  }
`
