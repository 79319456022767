import React from "react"
import cls from "classnames"
import PropTypes from "prop-types"
import "./index.scss"

const Button = (
  {
    children,
    type,
    onClick,
    className
  }
) => {
  const baseClasses = {
    btnPrimary: "button--primary"
  }

  const propsClass = {
    [baseClasses.btnPrimary]: type === "primary"
  }

  const buttonClass = cls(
    "button",
    propsClass,
    className
  )

  return (
    <button
      className={buttonClass}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

Button.defaultProps = {
  type: "primary",
  onClick: () => {
  }
}

Button.propTypes = {
  type: PropTypes.oneOf(["primary"]),
  onClick: PropTypes.func
}

export default Button

